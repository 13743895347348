import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 30px;
  grid-row: 2/2;
  grid-column: 2/2;
  background: #fafafa;
  display: flex;
  flex-direction: column;
`;
export default Wrapper;
