import styled from 'styled-components';
import logo from 'assets/img/logoReach.png';

const Wrapper = styled.div`
  width: 100%;
  img {
    margin: 20px 10px;
  }
`;

export const Logo = () => (
  <Wrapper>
    <img src={logo} alt="logo" />
  </Wrapper>
);
