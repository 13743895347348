import * as MdIcons from 'react-icons/md';

export const SideNavbarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <MdIcons.MdDashboard />,
    iconArrow: <MdIcons.MdOutlineChevronRight />,
    subNav: [
      {
        title: 'Dodaj fakturę',
        path: '/dashboard/addnewinvoice',
      },
      {
        title: 'Dodaj nowe aktywa',
        path: '/dashboard/addnewassets',
      },
      {
        title: 'Historia',
        path: '/dashboard/history',
      },
    ],
  },
  {
    title: 'Dane',
    path: '/data',
    icon: <MdIcons.MdOutlinePayment />,
    iconArrow: <MdIcons.MdOutlineChevronRight />,
    subNav: [
      {
        title: 'Faktury',
        path: '/data/invoiceslist',
      },
      {
        title: 'Dane o aktywach',
        path: '/data/assetslist',
      },
      {
        title: 'Ankiety',
        path: '/data/history',
      },
    ],
  },
  {
    title: 'Monitoring',
    path: '/monitoring',
    icon: <MdIcons.MdAutorenew />,
    iconArrow: <MdIcons.MdOutlineChevronRight />,
  },
  {
    title: 'Analizy',
    path: '/analysis',
    icon: <MdIcons.MdDataExploration />,
    iconArrow: <MdIcons.MdOutlineChevronRight />,
  },
  {
    title: 'Raportowanie',
    path: '/reporting',
    icon: <MdIcons.MdAccountBalance />,
    iconArrow: <MdIcons.MdOutlineChevronRight />,
  },
  {
    title: 'Strategia i cele',
    path: '/strategy',
    icon: <MdIcons.MdOutlineAssessment />,
    iconArrow: <MdIcons.MdOutlineChevronRight />,
  },
  {
    title: 'Ustawienia',
    path: '/settings',
    icon: <MdIcons.MdOutlineSettings />,
    iconArrow: <MdIcons.MdOutlineChevronRight />,
  },
];
