import strona from 'assets/img/stronaBudowa.jpg';
import styled from 'styled-components';
import React from 'react';

const Wrapper = styled.div`
  img {
    max-width: 100%;
  }
`;

const ConstructionSite = () => (
  <Wrapper>
    <img src={strona} alt="Strona w budowie" />
  </Wrapper>
);

export default ConstructionSite;
