import ConstructionSite from 'compomenets/Molecules/ConstrucionSite/ConstructionSite';
import React from 'react';
import Wrapper from './StyledPage';

export const Data = () => {
  return (
    <Wrapper>
      Data
      <ConstructionSite />
    </Wrapper>
  );
};
export const DataInvoicesList = () => {
  return (
    <Wrapper>
      Dashboard Invoices List
      <ConstructionSite />
    </Wrapper>
  );
};
export const DataAssetsList = () => {
  return (
    <Wrapper>
      Data Assets List
      <ConstructionSite />
    </Wrapper>
  );
};
export const DataHistory = () => {
  return (
    <Wrapper>
      Data History
      <ConstructionSite />
    </Wrapper>
  );
};
