import InfoCard from 'compomenets/Molecules/InfoCard/InfoCard';
import React, { useState, useEffect } from 'react';
import { costs } from 'data/costs';
import styled from 'styled-components';
import { Card } from 'compomenets/Atoms/Card/Card';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  grid-row: 2/2;
  grid-column: 2/2;
  color: var(--dark);
`;

const mockAPI = (success) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (costs) {
        resolve([...costs]);
      } else {
        reject({ message: 'Error' });
      }
    }, 500);
  });
};

const RowInfoCards = () => {
  const [costsHook, setCostsHook] = useState([]);
  const [isLoading, setLoading] = useState([]);

  useEffect(() => {
    setLoading(true);
    mockAPI()
      .then((data) => {
        setLoading(false);
        setCostsHook(costs);
      })
      .catch((err) => console.log(err));
  }, []);
  return isLoading ? (
    <Wrapper>
      <Card>
        <h1>Loading</h1>
      </Card>
    </Wrapper>
  ) : (
    <Wrapper>
      {costsHook.map((energyData) => (
        <InfoCard key={energyData.tag} energyData={energyData} />
      ))}
    </Wrapper>
  );
};

export default RowInfoCards;
