import React from 'react';
import styled from 'styled-components';
import andrzej from 'assets/img/Andrzej.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #342e59;
  margin-bottom: 34px;
  p {
    font-size: 20px;
    letter-spacing: 0.1px;
  }
  b {
    font-weight: 600;
  }
  p:last-child {
    font-size: 14px;
    color: #9b94c1;
  }
`;

const Picture = styled.div`
  border-radius: 50%;
  width: 95px;
  height: 95px;
  align-self: center;
  margin: 9px;
  margin-top: 30px;
`;

export const ProfilePic = () => {
  return (
    <Wrapper>
      <Picture>
        <img src={andrzej} alt="prfilepic" />
      </Picture>
      <p>
        Witaj, <b>Andrzej</b>
      </p>
      <p>a.komoar@vmail.com</p>
    </Wrapper>
  );
};
