import upIMG from 'assets/img/up.png';
import downIMG from 'assets/img/down.png';
import React from 'react';
import { StyledCard } from './InfoCardStyles';
import PropTypes from 'prop-types';

const InfoCard = ({ energyData: { tag, value, isUP, performance } }) => {
  return (
    <StyledCard>
      <p>{tag}</p>
      <p>{value}</p>
      <div>
        <img src={isUP === '1' ? upIMG : downIMG} alt="icon" />
        {performance}
      </div>
    </StyledCard>
  );
};

InfoCard.propTypes = {
  userData: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    value: PropTypes.string,
    isUP: PropTypes.string,
    performance: PropTypes.string,
  }),
};

export default InfoCard;
