import styled from 'styled-components';
import { Input } from 'compomenets/Atoms/Input/Input';
import { Label } from 'compomenets/Atoms/Label/Label';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px;

  ${Label} {
    margin-bottom: 5px;
  }
`;

export const FormField = ({ onChange, id, label, name, type = 'text' }) => {
  return (
    <Wrapper>
      <Label htmlFor={id}>{label}</Label>
      <Input name={name} id={id} type={type} onChange={onChange} />
    </Wrapper>
  );
};
