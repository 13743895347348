import { AddInvoice } from 'compomenets/Organisms/AddInvoice/AddInvoice';
import RowInfoCards from 'compomenets/Organisms/RowInfoCards/RowInfoCards';
import React from 'react';
import Wrapper from './StyledPage';
import ConstructionSite from 'compomenets/Molecules/ConstrucionSite/ConstructionSite';
import SubMenu from 'compomenets/Organisms/SubMenu/SubMenu';
import ChartDonut from 'compomenets/Molecules/Chart/ChartDonut';
import ChartBar from 'compomenets/Molecules/Chart/ChartBar';
import Podglad from 'compomenets/Organisms/Podglad/Podglad';
import DaneRynkowe from 'compomenets/Organisms/DaneRynkowe/DaneRynkowe';

export const Dashboard = () => {
  return (
    <Wrapper>
      <SubMenu />
      <RowInfoCards />
      <Podglad />
      <DaneRynkowe />
      {/* <ChartDonut /> */}
    </Wrapper>
  );
};
export const DashboardAddInvoice = () => {
  return (
    <Wrapper>
      Dashboard Add Invoice
      <AddInvoice />
    </Wrapper>
  );
};
export const DashboardAddNewAssets = () => {
  return (
    <Wrapper>
      Dashboard Add New Assets <ConstructionSite />
    </Wrapper>
  );
};
export const DashboardHistory = () => {
  return (
    <Wrapper>
      Historia
      <ConstructionSite />
    </Wrapper>
  );
};
