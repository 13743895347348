import React from 'react';
import styled from 'styled-components';
import { Logo } from 'compomenets/Atoms/Logo.js/Logo';
import { ProfilePic } from 'compomenets/Atoms/ProfilePic/ProfilePic';
import { SideNavbarData } from './SideNavbarData';
import { NavbarItem } from 'compomenets/Atoms/NabarItem/NavbarItem';

const Wrapper = styled.div`
  grid-row: 1/3;
  grid-column: 1/2;
  background-color: var(--white);
  box-shadow: 7px 7px 20px -10px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  p {
    align-self: center;
  }
`;

const SideNavbar = () => (
  <Wrapper>
    <Logo />
    <ProfilePic />
    {SideNavbarData.map((item, index) => {
      return <NavbarItem item={item} key={index} />;
    })}
  </Wrapper>
);
export default SideNavbar;
