import { Card } from 'compomenets/Atoms/Card/Card';
import React from 'react';
import styled from 'styled-components';
import * as MdIcons from 'react-icons/md';
import graph1 from 'assets/img/graph1.png';
import graph2 from 'assets/img/graph2.png';
import graph3 from 'assets/img/graph3.png';

const Wrapper = styled.div`
  color: var(--dark);
`;

const StyledButton = styled.button`
  font-size: 14px;
  border-radius: 35px;
  padding: 0 20px;
  margin: 10px;
  background: #2258bf;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  box-shadow: 7px 7px 20px -10px rgba(0, 0, 0, 0.4);
  p {
    padding-left: 5px;
  }
`;

const Row1 = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Row2 = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Row3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #eeeeee;
  color: var(--dark);
  border-radius: 40px;
  margin: 15px;
  height: 70px;
`;

const El = styled.div``;

const Rank = styled.div`
  background: #2258bf;
  border-radius: 60px;
  padding: 7px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DaneRynkowe = () => {
  return (
    <Wrapper>
      <Card>
        <Row1>
          <h1>Dane rynkowe</h1>
          <StyledButton>
            <MdIcons.MdCalendarToday />
            <p>Okres Czasu</p>
          </StyledButton>
        </Row1>
        <Row2>
          <El>
            Ran.
            <MdIcons.MdUnfoldMore />
          </El>
          <El>
            Kategoria
            <MdIcons.MdUnfoldMore />
          </El>
          <El>
            Cena <MdIcons.MdUnfoldMore />
          </El>
          <El>
            Zmiana (24h) <MdIcons.MdUnfoldMore />
          </El>
          <El>
            Graf liniowy <MdIcons.MdUnfoldMore />
          </El>
        </Row2>
        <Row3>
          <Rank>#1</Rank>
          <p>Cena enrgii elektrycznej</p>
          <p>500 zł/MWh</p>
          <p>2,54%</p>
          <img src={graph1} alt="icon" />
        </Row3>
        <Row3>
          <Rank>#2</Rank>
          <p>CO2 </p>
          <p>60 EUR/t</p>
          <p>1,94%</p>
          <img src={graph2} alt="icon" />
        </Row3>
        <Row3>
          <Rank>#3</Rank>
          <p>EUR/PLN</p>
          <p>4,58</p>
          <p>3,14%</p>
          <img src={graph3} alt="icon" />
        </Row3>
      </Card>
    </Wrapper>
  );
};

export default DaneRynkowe;
