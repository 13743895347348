import styled from 'styled-components';

export const Input = styled.input`
  background-color: var(--inputGrey);
  padding: 7px 10px;
  border: 1px solid grey;
  border-radius: 30px;
  box-shadow: 3px 3px 10px -3px rgba(0, 0, 0, 0.4);
  &:focus {
    outline: none;
    box-shadow: 7px 7px 20px -10px rgba(0, 0, 0, 0.5);
  }
`;
