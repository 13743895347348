export const costs = [
  {
    tag: 'Electricity Costs',
    value: '1591.54 PLN',
    performance: '30%',
    isUP: '1',
  },
  {
    tag: 'Electricity Consumption',
    value: '1839 kWh',
    performance: '19%',
    isUP: '0',
  },
  {
    tag: 'Scope 2 emissions',
    value: '1.66 t CO2',
    performance: '4%',
    isUP: '1',
  },
  {
    tag: 'Total Utilities Costs',
    value: '1591.54 PLN',
    performance: '45%',
    isUP: '0',
  },
];
