import { Card } from 'compomenets/Atoms/Card/Card';
import ChartBar from 'compomenets/Molecules/Chart/ChartBar';
import React from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
  font-size: 14px;
  border-radius: 5px;
  padding: 1px 10px;
  margin: 10px;
  border: 1px solid var(--dark);
  box-shadow: 7px 7px 20px -10px rgba(0, 0, 0, 0.4);
  color: var(--dark);
`;

const Row1 = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  p {
    color: var(--grey);
    font-size: 12px;
  }
`;

const Wrapper = styled.div`
  ${Card} {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  color: var(--dark);
`;

const Podglad = () => {
  return (
    <Wrapper>
      <Card>
        <Row1>
          <div>
            <h1>Podgląd</h1>
            <p>Zestaw kosztów w wybranym okresie</p>
          </div>
          <StyledSelect name="zasob" id="zasob">
            <option value="2021">Rok (2021)</option>
            <option value="2020">Rok (2020)</option>
            <option value="2019">Rok (2019)</option>
            <option value="2018">Rok (2018)</option>
          </StyledSelect>
        </Row1>
        <h2>13 221.26 PLN</h2>
        <p>Ostatni miesiąc 563.43 PLN</p>
        <ChartBar />
      </Card>
    </Wrapper>
  );
};

export default Podglad;
