import React from 'react';
import styled from 'styled-components';
import { Routes, Route, Navigate } from 'react-router-dom';
import SideNavbar from 'compomenets/Organisms/SideNavbar/SideNavbar';
import { TopNavbar } from 'compomenets/Organisms/TopNavbar/TopNavbar';
import { Dashboard, DashboardAddInvoice, DashboardAddNewAssets, DashboardHistory } from 'Pages/Dashboard';
import { Data, DataInvoicesList, DataAssetsList, DataHistory } from 'Pages/Data';
import { Monitoring } from 'Pages/Monitoring';
import { Analysis } from 'Pages/Analysis';
import { Reporting } from 'Pages/Reporting';
import { Settings } from 'Pages/Setting';
import { Strategy } from 'Pages/Strategy';
import { News } from 'Pages/News';
import { Training } from 'Pages/Training';
import { Blog } from 'Pages/Blog';
import { Intranet } from 'Pages/Intranet';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  display: grid;
  grid-template-columns: 345px auto;
  grid-template-rows: 120px auto;
`;

const App = () => (
  <>
    <Wrapper>
      <TopNavbar />
      <SideNavbar />
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/addnewinvoice" element={<DashboardAddInvoice />} />
        <Route path="/dashboard/addnewassets" element={<DashboardAddNewAssets />} />
        <Route path="/dashboard/history" element={<DashboardHistory />} />
        <Route path="/data" element={<Data />} />
        <Route path="/data/invoiceslist" element={<DataInvoicesList />} />
        <Route path="/data/assetslist" element={<DataAssetsList />} />
        <Route path="/data/history" element={<DataHistory />} />
        <Route path="/monitoring" element={<Monitoring />} />
        <Route path="/strategy" element={<Strategy />} />
        <Route path="/analysis" element={<Analysis />} />
        <Route path="/reporting" element={<Reporting />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/news" element={<News />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/intranet" element={<Intranet />} />
        <Route path="/training" element={<Training />} />
      </Routes>
    </Wrapper>
  </>
);

export default App;
