import styled from 'styled-components';
import { Card } from 'compomenets/Atoms/Card/Card';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 250px;
  background-color: ${({ isRed }) => (isRed ? 'red' : '')};
  p:first-child {
    font-size: var(--l);
    align-self: flex-start;
  }
  p:nth-child(2) {
    font-size: var(--xl);
    font-weight: bold;
    margin: 5px 0;
  }

  @media only screen and (max-width: 600px) {
    margin: 5px;
    padding: 5px;
    width: 100%;
  }
`;
