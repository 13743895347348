import React from 'react';
import styled from 'styled-components';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

const Wrapper = styled.div`
  max-width: 300px;
`;

const data = {
  labels: ['Kompletne', 'Ocenianie', 'Przesłane', 'Brakujące'],
  datasets: [
    {
      data: [14, 16, 28, 5],
      backgroundColor: ['#00ADA3', '#FF782C', '#374C98', '#FFAB2D'],
    },
  ],
};

const ChartDonut = () => (
  <Wrapper>
    <Doughnut data={data} />
  </Wrapper>
);

export default ChartDonut;
