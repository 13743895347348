import React from 'react';
import styled from 'styled-components';
import * as MdIcons from 'react-icons/md';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
const StyledParagraph = styled.p`
  font-weight: 600;
  font-size: 34px;
  color: var(--dark);
`;
const StyledSelect = styled.select`
  font-size: 14px;
  border-radius: 35px;
  padding: 0 20px;
  margin: 10px;
  border: none;
  box-shadow: 7px 7px 20px -10px rgba(0, 0, 0, 0.4);
  color: var(--dark);
`;
const StyledButton = styled.button`
  font-size: 14px;
  border-radius: 35px;
  padding: 0 20px;
  margin: 10px;
  background: #2258bf;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  box-shadow: 7px 7px 20px -10px rgba(0, 0, 0, 0.4);
  p {
    padding-left: 5px;
  }
`;

const SubMenu = () => (
  <Wrapper>
    <StyledParagraph>Pulpit Startowy</StyledParagraph>
    <StyledSelect name="zasob" id="zasob">
      <option value="energia">Energia Elektryczna</option>
      <option value="gaz">Gaz</option>
      <option value="woda">Woda</option>
      <option value="paliwo">Paliwo</option>
    </StyledSelect>
    <StyledSelect name="zaklad" id="zaklad">
      <option value="zaklad1">Zakład 1</option>
      <option value="zaklad2">Zakład 2</option>
      <option value="zaklad3">Zakład 3</option>
      <option value="zaklad4">Zakład 4</option>
    </StyledSelect>
    <StyledSelect name="aktywa" id="aktywa">
      <option value="wszystkie">Wszystkie Aktywa</option>
      <option value="lekki">Lekki Sprzęt</option>
      <option value="ciezki">Cięzki sprzęt</option>
    </StyledSelect>
    <StyledSelect name="pogoda" id="pogoda">
      test
      <option value="poznan">Poznań, PL</option>
      <option value="warszawa">Warszawa, PL</option>
      <option value="katowice">Katowice, PL</option>
    </StyledSelect>
    <StyledButton>
      <MdIcons.MdCalendarToday />
      <p>Okres Czasu</p>
    </StyledButton>
  </Wrapper>
);

export default SubMenu;
