import React from 'react';
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import styled from 'styled-components';
ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip);

const Wrapper = styled.div`
  margin: 20px;
  height: 300px;
`;

const labels = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];

const options = {
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        barPercentage: 0.1,
      },
    ],
  },
};

const data = {
  labels,
  datasets: [
    {
      type: 'line',
      label: 'Koszt netto',
      borderColor: '#2258BF',
      borderWidth: 2,
      fill: false,
      data: [2150, 1700, 1650, 1950, 1350, 650, 1150, 1250, 1550, 1750, 1850, 1800],
    },
    {
      type: 'bar',
      label: 'kWh',
      backgroundColor: ['#4dff0d', '#4dff0d', '#4dff0d', '#4dff0d', '#4dff0d', '#4dff0d', '#4dff0d', '#4dff0d', '#4dff0d', '#e6e6e6', '#e6e6e6', '#e6e6e6'],
      data: [1950, 1500, 1600, 1900, 1350, 750, 1100, 1200, 1250, 1550, 1600, 1700],
      borderColor: 'white',
      borderWidth: 1,
      barThickness: 30,
    },
  ],
};

const ChartBar = () => {
  return (
    <Wrapper>
      <Chart type="bar" data={data} options={options} />
    </Wrapper>
  );
};

export default ChartBar;
