import styled from 'styled-components';

export const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: var(--green);
  color: var(--black);
  display: flex;
  border: none;
  border-radius: 20px;
  align-items: center;
  stroke: black;
`;
