import { Input } from 'compomenets/Atoms/Input/Input';
import styled from 'styled-components';
import * as MdIcons from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { IconContext } from 'react-icons/lib';

import React from 'react';

const Wrapper = styled.nav`
  box-shadow: 7px 7px 20px -10px rgba(0, 0, 0, 0.4);
  background-color: var(--white);
  grid-row: 1/1;
  grid-column: 2/2;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  /* padding: 10px;
  min-width: 550px;
  input {
    border: none;
    margin-left: 10px;
    width: 250px;
  } */
`;

const StyledNavlink = styled(NavLink)`
  padding: 25px;
  font-size: 16px;
  color: #342e59;
`;

const StyledIcon = styled.div`
  background: #eeeeee;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -15px;
`;

export const TopNavbar = () => {
  return (
    <IconContext.Provider value={{ size: '25px' }}>
      <Wrapper>
        <StyledNavlink to="/intranet"> Intranet </StyledNavlink>
        <StyledNavlink to="/training"> EDU </StyledNavlink>
        <StyledNavlink to="/blog"> BLOG </StyledNavlink>
        <StyledNavlink to="/news"> INFO </StyledNavlink>
        <StyledNavlink icon to="#">
          <StyledIcon>
            <MdIcons.MdOutlineNotificationsNone />
          </StyledIcon>
        </StyledNavlink>
        <StyledNavlink icon to="#">
          <StyledIcon>
            <MdIcons.MdOutlineMessage />
          </StyledIcon>
        </StyledNavlink>
        <StyledNavlink icon to="#">
          <StyledIcon>
            <MdIcons.MdLanguage />
          </StyledIcon>
        </StyledNavlink>
        <StyledNavlink icon to="#">
          <StyledIcon>
            <MdIcons.MdOutlineSettings />
          </StyledIcon>
        </StyledNavlink>
      </Wrapper>
    </IconContext.Provider>
  );
};
