import styled from 'styled-components';

import React, { useState } from 'react';
import { Card } from 'compomenets/Atoms/Card/Card';
import { FormField } from 'compomenets/Molecules/FormField/FormField';
import Button from 'compomenets/Atoms/Button/Button';

export const AddInvoice = () => {
  const [input, setInput] = useState({
    nr: '',
    kwota: '',
    okres: '',
  });

  const handleNameChange = (e) => {
    setInput(e.target.value);
  };

  return (
    <Card>
      <FormField name="nr" id="nr" label="Numer faktury" value={input} onChange={handleNameChange} />
      <FormField name="kwota" id="kwota" label="Kwota" />
      <FormField name="okres" id="okres" label="Okres Rozliczeniowy" />
      <Button />
    </Card>
  );
};
