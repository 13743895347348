import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { IconContext } from 'react-icons/lib';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .active {
    border-radius: 2em;
    background: var(--lightBlue);
    color: var(--blue);
    font-weight: 600;
    box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.4);
  }
`;

const StyledLink = styled(NavLink)`
  color: var(--grey);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 67px;
  padding: 0px 35px;
  margin: 4px 0px;
  font-size: 16px;
  text-decoration: none;
  list-style: none;
  transition: 0.2s;
  width: 85%;
  border-radius: 2em;
  &:hover {
    background-color: var(--lightGrey);
    cursor: pointer;
    box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.4);
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;
const StyledArrow = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  transition: 0.3s;
  transform: ${({ subnav }) => (subnav ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

export const NavbarItem = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <IconContext.Provider value={{ size: '25px' }}>
      <Wrapper>
        <StyledLink activeClassName="active" subnav={subnav} to={item.path} onClick={item.subNav && showSubnav}>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
          <StyledArrow subnav={subnav}>{item.subNav ? item.iconArrow : null}</StyledArrow>
        </StyledLink>
        {subnav &&
          item.subNav.map((item, index) => {
            return (
              <StyledLink subnav={subnav} activeClassName="active" to={item.path} key={index}>
                {item.title}
              </StyledLink>
            );
          })}
      </Wrapper>
    </IconContext.Provider>
  );
};
